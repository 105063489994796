import React, { useState, useEffect, lazy, Suspense } from 'react';
import Lottie from 'react-lottie';
import loaderAnimation from './images/loader.json';

const Header = lazy(() => import('./components/Header'));
const About = lazy(() => import('./components/About'));
const Resume = lazy(() => import('./components/Resume'));
const Portfolio = lazy(() => import('./components/Portfolio'));
const ContactUs = lazy(() => import('./components/ContactUs'));
const Footer = lazy(() => import('./components/Footer'));



const App = () => {
  const [resumeData, setResumeData] = useState({});

  const getData = () => {
    fetch('js/resumeData.json', {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setResumeData(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="App">
      <Suspense fallback={
        <div className="loader-container">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      }>
        <Header resumeData={resumeData} />
        <About resumeData={resumeData} />
        <Resume resumeData={resumeData} />
        <Portfolio resumeData={resumeData} />
        <ContactUs resumeData={resumeData} />
        <Footer resumeData={resumeData} />
      </Suspense>
    </div>
  );
}

export default App;